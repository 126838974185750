import React, { useState, useEffect, useRef } from "react";

const InfiniteVerticalCarousel = ({
  direction = "down",
  speed = 50,
  list = [],
}) => {
  const [items] = useState([...list, ...list]);
  const containerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const scrollSpeed = direction === "up" ? -speed : speed; // Pixels per second
    const totalHeight = container.scrollHeight / 2;
    let lastTime = null;
    let animationFrameId = null;

    const step = (timestamp) => {
      if (!lastTime) lastTime = timestamp;
      const delta = timestamp - lastTime;
      lastTime = timestamp;

      setScrollPosition((prevPosition) => {
        let newPosition = prevPosition + (scrollSpeed * delta) / 1000;

        if (direction === "down" && newPosition >= totalHeight) {
          newPosition -= totalHeight;
        } else if (direction === "up" && newPosition <= 0) {
          newPosition += totalHeight;
        }

        return newPosition;
      });

      animationFrameId = requestAnimationFrame(step);
    };

    animationFrameId = requestAnimationFrame(step);

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [direction, speed]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = scrollPosition;
    }
  }, [scrollPosition]);

  return (
    <div className="h-64 xl:h-96 overflow-hidden relative">
      <div className="absolute inset-0 bg-gradient-to-b from-[#8cb4eb] via-transparent to-[#8cb4eb] pointer-events-none z-10"></div>
      <div ref={containerRef} className="h-full overflow-hidden">
        <div className="flex flex-col">
          {items.map((item, index) => (
            <div
              key={`${item.name}-${index}`}
              className="group h-16 lg:h-20 xl:h-24 w-16 md:w-auto my-2 flex flex-col items-center justify-center text-white"
            >
              <div className="w-10 md:w-12 lg:w-16 xl:w-20">{item.Icon}</div>
              <span className="mt-1 text-xs md:text-sm invisible group-hover:visible select-none text-center break-all md:break-words">
                {item.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InfiniteVerticalCarousel;

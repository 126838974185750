import React, { useEffect, useRef, useState } from "react";

//TODO fix bad margin on some window resizes

const SlidingSection = ({
  zIndex,
  className,
  children,
  color,
  totalTravel = 0.8,
  minBound = 0.4,
  delay = 0,
}) => {
  // totalTravel 80% of the section height
  // delay before start moving, porcentual in the same units as totalTravel

  const [height, setHeight] = useState(0);
  const [negativeMargin, setMargin] = useState(0);
  const sectionRef = useRef(null);

  const calculateTranslation = () => {
    if (sectionRef.current) {
      const rect = sectionRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      let visibleHeightRatio = (windowHeight - rect.top) / windowHeight;
      visibleHeightRatio = visibleHeightRatio - (totalTravel + delay);
      if (visibleHeightRatio < 0) {
        setHeight(0);
      } else {
        const nPercentage = Math.min(
          minBound,
          totalTravel * visibleHeightRatio
        );
        const nHeight = Math.floor(rect.height * nPercentage);
        setHeight(nHeight);
      }
    }
  };

  const calculateMargin = () => {
    if (sectionRef.current) {
      const rect = sectionRef.current.getBoundingClientRect();
      setMargin(Math.round(rect.height * totalTravel));
    }
  };

  useEffect(() => {
    calculateMargin();
  }, [sectionRef]);

  useEffect(() => {
    const handleScroll = () => {
      requestAnimationFrame(calculateTranslation);
    };

    const handleResize = () => {
      // requestAnimationFrame(() => {
      calculateMargin();
      calculateTranslation();
      // });
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    // Initial calculation
    calculateTranslation();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`relative w-full transition-transform duration-200 ${
        className || ""
      }`}
      style={{
        zIndex,
        marginTop: `-${negativeMargin}px`,
      }}
      ref={sectionRef}
    >
      <div
        className="block transition-transform duration-200"
        style={{
          height: `${height}px`,
          backgroundColor: color || "#0000",
        }}
      ></div>
      {children}
    </div>
  );
};

export default SlidingSection;

import React, { useState, useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "assets/img/logos/isologo.svg";
import { setDefaultOptions } from "date-fns";
import * as DateLocales from "date-fns/locale";

function countryCodeToFlag(countryCode) {
  if (countryCode === "en") countryCode = "gb";
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
}

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const { pathname } = location;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 0) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const navItems = [
    { id: 1, text: "Home", path: "/" },
    // { id: 2, text: "RSSP", path: "/RSSP" },
    { id: 3, text: "About", path: "/About" },
    { id: 4, text: "Support", path: "https://revelat.atlassian.net/" },
  ];

  const urlCheck = (path, location) => {
    if (path === "/") return path === location;
    return location.includes(path);
  };

  const NavItem = ({ destination, location, text, toggleMenu }) => {
    return (
      <NavLink
        end
        to={destination}
        className={`py-1.5 px-4 border rounded-full hover:border-blue-500 duration-300 hover:text-blue-500 ${
          urlCheck(destination, location)
            ? "border-blue-500 text-blue-500"
            : "border-transparent"
        }`}
        onClick={toggleMenu ? toggleMenu : undefined}
      >
        {text}
      </NavLink>
    );
  };

  const LangSelector = () => {
    const { i18n } = useTranslation();
    const langs = i18n.options.whitelist || [];
    const options = langs.map((lang) => ({
      id: lang,
      label: `${countryCodeToFlag(lang)} ${lang.toUpperCase()}`,
    }));

    const setDateFNSLocale = (lang) => {
      if (DateLocales[lang]) setDefaultOptions({ locale: DateLocales[lang] });
      else setDefaultOptions({ locale: DateLocales.enUS });
    };

    const changeLanguage = (e) => {
      i18n.changeLanguage(e.target.value);
      setDateFNSLocale(e.target.value);
    };

    useEffect(() => {
      setDateFNSLocale(i18n.resolvedLanguage);
    }, []);

    return (
      <select
        className="bg-transparent border border-transparent hover:border-blue-500 duration-300 hover:text-blue-500 rounded-full pl-5 pr-2 py-1.5 cursor-pointer"
        onChange={changeLanguage}
        defaultValue={i18n.resolvedLanguage}
      >
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.label}
          </option>
        ))}
      </select>
    );
  };

  return (
    <header
      className={`w-full z-10 fixed top-0 transition duration-500 ease-in-out font-medium ${
        hasScrolled ? "bg-white shadow-md" : ""
      }`}
    >
      {/* Container */}
      <div className="max-w-screen-xl flex justify-between items-center mx-auto p-3 md:px-10 lg:px-8 md:py-5">
        {/* Logo */}
        <div className={`w-full transition duration-500 ease-in-out`}>
          <Logo alt="revelat logo" className="h-12 md:h-16" />
        </div>

        {/* Desktop Navigation */}
        <nav>
          <div className="hidden md:flex items-center">
            {navItems.map((item) => (
              <div key={item.id} className="px-4">
                <NavItem
                  destination={item.path}
                  location={pathname}
                  text={t(item.text)}
                />
              </div>
            ))}
            <div className="px-4">
              <LangSelector />
            </div>
          </div>
        </nav>

        {/* Mobile Navigation Icon */}
        <div onClick={toggleMenu} className="block md:hidden cursor-pointer">
          <div className="block mx-3">
            <span
              className={`w-5 h-0.5 my-1.5 bg-black block relative transition duration-300 ease-out ${
                isOpen && "origin-center rotate-45 top-[8px]"
              }`}
            ></span>
            <span
              className={`w-5 h-0.5 my-1.5 bg-black block relative transition duration-300 ease-out ${
                isOpen && "opacity-0"
              }`}
            ></span>
            <span
              className={`w-5 h-0.5 my-1.5 bg-black block relative transition duration-300 ease-out ${
                isOpen && "origin-center -rotate-45 -top-[8px]"
              }`}
            ></span>
          </div>
        </div>

        <nav>
          {/* Mobile Navigation Menu */}
          <ul
            className={`fixed w-[40%] min-w-48 ease-in-out duration-500 top-0
            ${
              isOpen
                ? "md:hidden left-0 h-full shadow-md bg-white"
                : "bottom-0 left-[-100%]"
            }`}
          >
            {/* Mobile Logo */}
            <div className="m-4">
              <Logo alt="revelat logo" width={64} height={64} />
            </div>

            {/* Mobile Navigation Items */}
            {navItems.map((item) => (
              <li key={item.id} className="p-4">
                <NavItem
                  destination={item.path}
                  location={pathname}
                  text={t(item.text)}
                  toggleMenu={toggleMenu}
                />
              </li>
            ))}
            <li className="p-4">
              <LangSelector />
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;

import EventBus from "utils/EventBus";

const ContactButton = () => {
  const showContactModal = () => {
    EventBus.dispatch("ShowModal");
  };

  return (
    <div className="fixed bottom-4 right-4 text-white font-bold z-50">
      <i
        className="lni lni-envelope bg-primary-500 hover:bg-primary-600 p-3 rounded-md transition duration-300 cursor-pointer border border-white"
        style={{ fontWeight: 800 }}
        onClick={showContactModal}
      ></i>
    </div>
  );
};

export default ContactButton;

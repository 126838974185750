import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import "echarts-gl";
import geoJson from "assets/data/world.json";

echarts.registerMap("world", geoJson);

function ECharts({ options, style, className, loading }) {
  const [chart, setChart] = useState(null);
  const chartRef = useRef();

  useEffect(() => {
    const chart = echarts.init(chartRef.current);
    chart.setOption({ ...options, resizeObserver }, true); // second param is for 'noMerge'
    setChart(chart);
    if (resizeObserver) resizeObserver.observe(chartRef.current);
  }, [options]);

  useEffect(() => {
    if (!chart) {
      return;
    }
    if (loading) {
      chart.showLoading();
      return;
    }

    chart.hideLoading();
  }, [chart, loading]);

  useEffect(() => {
    if (chart && options) {
      chart.clear();
    }
  }, [options]);

  const newStyle = {
    // height: 350,
    ...style,
  };
  // className="echarts-parent position-relative">
  return <div ref={chartRef} style={newStyle} className={className} />;
}

const resizeObserver = new window.ResizeObserver((entries) => {
  entries.map(({ target }) => {
    const instance = echarts.getInstanceByDom(target);
    if (instance) {
      instance.resize();
    }
  });
});

export default React.memo(ECharts);

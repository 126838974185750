import { ReactComponent as Logo } from "assets/img/logos/isologo.svg";

const socials = [
  {
    icon: "lni-linkedin-original",
    link: "https://www.linkedin.com/company/revelat-software/about/",
  },
];

const Footer = () => {
  return (
    <footer className="bg-gray-200">
      <div className="mx-auto px-3 py-8 max-w-xl md:max-w-4xl lg:max-w-screen-lg xl:max-w-screen-xl">
        <div className="flex flex-wrap md:justify-around">
          <div className="px-3 w-full md:w-1/4">
            <div>
              <div className="flex items-center mb-1">
                <Logo alt="revelat logo" height={80} className="inline" />
              </div>
              <p className="">Make it simple, make it real</p>
            </div>
          </div>
          <div className="px-3 mt-6 md:mt-0 w-full md:w-1/4">
            <div className="flex h-full">
              <ul className="flex justify-start items-center text-xl text-white">
                {socials.map((social, index) => (
                  <li key={index}>
                    <a
                      className="w-11 h-11 mr-3 bg-blue-400 rounded-full flex justify-center items-center"
                      href={social.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className={`lni ${social.icon}`}></i>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

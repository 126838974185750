const InputContainer = ({ small = false, children }) => {
  return (
    <div
      className={`w-full max-w-full md:basis-auto md:grow-0 px-3 ${
        small && "md:w-1/2"
      }`}
    >
      {children}
    </div>
  );
};

export default InputContainer;

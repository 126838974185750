import React from "react";
import Hero from "./sections/Hero";
import Experience from "./sections/Experience";
import Customers from "./sections/Customers";
import Talent from "./sections/Talent";
import Services from "./sections/Services";
import Partners from "./sections/Partners";
import RevealOnScroll from "utils/RevealOnScroll";

const Home = () => {
  return (
    <>
      <Hero />
      <RevealOnScroll>
        <Services />
      </RevealOnScroll>
      <Talent />
      <RevealOnScroll>
        <Experience />
      </RevealOnScroll>
      <RevealOnScroll>
        <Partners />
      </RevealOnScroll>
      {/* <RevealOnScroll>
        <Customers />
      </RevealOnScroll> */}
    </>
  );
};

export default Home;

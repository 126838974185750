import React, { useState, useEffect } from "react";
import EventBus from "utils/EventBus";
import ContactForm from "./ContactForm";

const ContactModal = () => {
  const [state, setState] = useState(false);

  useEffect(() => {
    EventBus.on("ShowModal", () => {
      setState(true);
    });

    return () => {
      EventBus.remove("ShowModal");
    };
  }, []);

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!state || keyCode !== 27) return;
      setState(false);
    };
    if (state) document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [state]);

  if (!state) return null;

  return (
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      <div className="relative flex items-center p-4 max-w-2xl h-full z-50 w-full">
        <div className="relative w-full max-h-full bg-gray-100 rounded shadow">
          {/* modal header */}
          <div className="flex px-5 py-3">
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              onClick={() => setState(false)}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {/* modal body */}
          <div className="max-h-80vh px-5 mb-6 overflow-y-auto">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;

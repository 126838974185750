import { useTranslation } from "react-i18next";
import Section from "components/Section";
import RevealOnScroll from "utils/RevealOnScroll";

const experiences = [
  {
    icon: "lni-code-alt",
    id: "atm",
  },
  {
    icon: "lni-dashboard",
    id: "teller",
  },
  {
    icon: "lni-money-location",
    id: "banking",
  },
  {
    icon: "lni-harddrive",
    id: "hardware",
  },
  {
    icon: "lni-code",
    id: "framework",
  },
  {
    icon: "lni-vector",
    id: "mobile",
  },
];

const ServiceTile = ({ icon, id, t }) => {
  return (
    <div className="px-3">
      <div className="md:h-[500px] lg:h-[570px] xl:h-[470px] transition duration-300 mb-12 py-[60px] px-9 shadow text-center rounded-3xl border-b-4 border-blue-900 hover:border-blue-500 hover:shadow-2 hover:shadow-blue-500/35">
        <div className="text-blue-500 text-5xl flex justify-center items-center m-auto mb-5 relative h-[100px] w-28">
          <i className={`lni ${icon}`} style={{ fontWeight: 600 }}></i>
          <svg
            width="110"
            height="72"
            viewBox="0 0 110 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute top-0 left-0 z-[-1]"
          >
            <path
              d="M110 54.7589C110 85.0014 85.3757 66.2583 55 66.2583C24.6243 66.2583 0 85.0014 0 54.7589C0 24.5164 24.6243 0 55 0C85.3757 0 110 24.5164 110 54.7589Z"
              fill="#EBF4FF"
            />
          </svg>
        </div>
        <h5 className="text-2xl mb-6">
          {t(`HomePage.Experience.tiles.${id}.title`)}
        </h5>
        <p>{t(`HomePage.Experience.tiles.${id}.description`)}</p>
      </div>
    </div>
  );
};

const Experience = () => {
  const { t } = useTranslation();
  return (
    <Section name="experiences" title={t("HomePage.Experience.title")}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {experiences.map((service, index) => (
          <RevealOnScroll key={`service-${index}`}>
            <ServiceTile {...service} t={t} />
          </RevealOnScroll>
        ))}
      </div>
    </Section>
  );
};

export default Experience;

import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { ReactComponent as ErrorImage } from "assets/img/error/404.svg";

const Error = () => {
  const { t } = useTranslation();
  return (
    <div className="h-[calc(100vh-240px)] md:h-[calc(100vh-172px)]">
      <ErrorImage className="w-[90%] max-w-md m-auto pt-24 md:pt-28 pb-12" />
      <div className="text-center px-3">
        <h2 className="hidden md:block text-3xl md:text-4xl">
          {t("ErrorPage.title")}
        </h2>
        <p className="mt-3 px-5 md:mt-8">{t("ErrorPage.subtitle")}</p>
        <div className="mt-8 md:mt-12">
          <NavLink className="primary-button" end to="/">
            {t("ErrorPage.button")}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Error;

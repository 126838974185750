import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ContactButton from "./components/ContactButton";

const GeneralLayout = () => (
  <>
    <Header />
    <Outlet />
    <Footer />
    <ContactButton />
  </>
);

export default GeneralLayout;

import React, { useEffect } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import ScrollToTop from "utils/ScrollToTop";
import ReactGA from "react-ga";
import "./utils/i18n";

// Layouts
import GeneralLayout from "./layouts/GeneralLayout";

// Pages
import Home from "./pages/Home";
import RSSP from "./pages/RSSP";
import About from "./pages/About";
import Error from "pages/Error";

// Partials
import ContactModal from "components/ContactModal";

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE, {
  testMode: process.env.NODE_ENV === "test",
});

const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

function App() {
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    trackPage(page);
  }, [location]);

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<GeneralLayout />}>
          <Route index element={<Home />} />
          {/* <Route path="RSSP" element={<RSSP />} /> */}
          <Route path="About" element={<About />} />
          <Route path="*" element={<Error />} />
        </Route>
      </Routes>
      <ContactModal />
    </>
  );
}

export default App;

import React, { useState, useEffect } from "react";

const WordCarousel = ({
  words = [],
  interval = 2000,
  className = "",
  elementClass = "",
  width = "w-24",
  height = "h-8",
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
        setIsTransitioning(false);
      }, 500); // Half of the transition duration
    }, interval);

    return () => clearInterval(timer);
  }, [words, interval]);

  return (
    <span
      className={`inline-block relative overflow-hidden ${className} ${width} ${height}`}
    >
      {words.map((word, index) => (
        <span
          key={index}
          className={`absolute inset-0 flex items-center transition-all duration-500 ease-in-out ${elementClass} 
            ${
              index === currentIndex
                ? isTransitioning
                  ? "-translate-y-full opacity-0 "
                  : "translate-y-0 opacity-100 "
                : index === (currentIndex + 1) % words.length && isTransitioning
                ? "translate-y-0 opacity-100 "
                : "translate-y-full opacity-0 "
            }`}
        >
          {word}
        </span>
      ))}
    </span>
  );
};

export default WordCarousel;

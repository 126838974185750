import ContentContainer from "./ContentContainer";

const Section = ({
  name,
  title,
  subtitle,
  children,
  className,
  position = "center",
}) => {
  const positionMap = {
    center: "justify-center",
    left: "justify-start md:ml-10",
    right: "justify-end md:mr-10",
  };

  return (
    <section id={name} className={className}>
      <ContentContainer>
        <div className={`flex flex-wrap ${positionMap[position] || ""}`}>
          <div className="md:w-1/2 lg:w-2/5 text-center mb-16">
            <h3 className="text-4xl mb-4 tracking-wide drop-shadow-md">
              {title}
            </h3>
            {subtitle && <p className="whitespace-pre-wrap">{subtitle}</p>}
          </div>
        </div>
        {children}
      </ContentContainer>
    </section>
  );
};

export default Section;

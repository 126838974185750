const outbound = [
  [
    //Buenos aires
    [-79.395137, 43.654987],
    [-58.378722, -34.606809],
  ],
  [
    //Tokyo
    [-79.395137, 43.654987],
    [139.7756295582265, 35.67696597457692],
  ],
  [
    //Kuwait
    [-79.395137, 43.654987],
    [47.978674042988665, 29.334796890892108],
  ],
  //   [
  //     //Nicosia
  //     [-79.395137, 43.654987],
  //     [33.36107684568585, 35.157629721965876],
  //   ],
  [
    //Madrid
    [-79.395137, 43.654987],
    [-3.6923227014914457, 40.42608941380132],
  ],
  [
    //London
    [-79.395137, 43.654987],
    [-0.2664031, 51.5287398],
  ],
  [
    //Paris
    [-79.395137, 43.654987],
    [2.264635, 48.8589384],
  ],
  [
    //Zurich
    [-79.395137, 43.654987],
    [8.4543354, 47.3775284],
  ],
  [
    //Rome
    [-79.395137, 43.654987],
    [12.3711917, 41.9102088],
  ],
  [
    //Montevideo
    [-79.395137, 43.654987],
    [-56.2798719, -34.8342805],
  ],
  [
    //Santiago
    [-79.395137, 43.654987],
    [-70.7946377, -33.4723925],
  ],
  [
    //Lima
    [-79.395137, 43.654987],
    [-77.1525882, -12.0262542],
  ],
  [
    //La Paz
    [-79.395137, 43.654987],
    [-68.2064784, -16.5205316],
  ],
  [
    //Asuncion
    [-79.395137, 43.654987],
    [-57.6804897, -25.2966746],
  ],
  [
    //Mexico City
    [-79.395137, 43.654987],
    [-99.3084216, 19.3909832],
  ],
  [
    //Kingston
    [-79.395137, 43.654987],
    [-76.8418559, 18.0180136],
  ],
  [
    //Lisboa
    [-79.395137, 43.654987],
    [-9.2009353, 38.7441392],
  ],
  [
    //Brussels
    [-79.395137, 43.654987],
    [4.2930172, 50.8552034],
  ],
  [
    //Amsterdam
    [-79.395137, 43.654987],
    [4.8215606, 52.3547418],
  ],
  [
    //Berlin
    [-79.395137, 43.654987],
    [13.2599275, 52.5069386],
  ],
  [
    //Podgorica
    [-79.395137, 43.654987],
    [19.1734786, 42.4320979],
  ],
  [
    //Podgorica
    [-79.395137, 43.654987],
    [19.1734786, 42.4320979],
  ],
  [
    //Pristina
    [-79.395137, 43.654987],
    [21.1175279, 42.6664358],
  ],
  [
    //Belgrade
    [-79.395137, 43.654987],
    [20.2577913, 44.8153704],
  ],
  [
    //Skopje
    [-79.395137, 43.654987],
    [21.3836908, 41.9991467],
  ],
  [
    //Athens
    [-79.395137, 43.654987],
    [23.6971399, 37.9908977],
  ],
  [
    //Beirut
    [-79.395137, 43.654987],
    [35.4304282, 34.1582323],
  ],
  [
    //Amman
    [-79.395137, 43.654987],
    [35.7828231, 31.8359458],
  ],
  [
    //Cairo
    [-79.395137, 43.654987],
    [31.2172648, 30.0595563],
  ],
  [
    //Damascus
    [-79.395137, 43.654987],
    [36.2416959, 33.5075402],
  ],
  [
    //Riyadh
    [-79.395137, 43.654987],
    [46.4928841, 24.7254554],
  ],
  [
    //Asmara
    [-79.395137, 43.654987],
    [38.8755289, 15.3258961],
  ],
  [
    //Addis Ababa
    [-79.395137, 43.654987],
    [38.6957437, 8.9633373],
  ],
  [
    //Djibouti
    [-79.395137, 43.654987],
    [43.1145799, 11.5748003],
  ],
  [
    //Sana'a
    [-79.395137, 43.654987],
    [44.1288796, 15.3831949],
  ],
  [
    //Muscat
    [-79.395137, 43.654987],
    [58.3421154, 23.5829604],
  ],
  [
    //Abu Dhabi
    [-79.395137, 43.654987],
    [54.3938119, 24.3870541],
  ],
  [
    //Doha
    [-79.395137, 43.654987],
    [51.4295958, 25.2841414],
  ],
  [
    //Manama
    [-79.395137, 43.654987],
    [50.5477922, 26.2266878],
  ],
];

export { outbound };

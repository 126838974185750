const Input = ({ type = "text", name, onChange, placeholder, icon, error }) => {
  return (
    <div className="relative mb-5">
      <input
        type={type}
        id={name}
        name={name}
        className="relative pr-5 pl-12 w-full shadow rounded h-14"
        placeholder={placeholder}
        onChange={onChange}
      />
      <i className={`lni ${icon} absolute left-5 top-5`}></i>
      {error && <span className="text-red-500 block mt-2 ml-2">{error}</span>}
    </div>
  );
};

export default Input;

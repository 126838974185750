import React, { useState, useEffect } from "react";
import LineIcon from "react-lineicons";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import heroImage from "assets/img/hero/rocket.svg";
import Carousel from "components/Carousel";
import Typewriter from "typewriter-effect";

const HeroBg = () => {
  return (
    <svg fill="none" viewBox="0 0 1440 767" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m-14.8936 273.223c3.6985-23.916 20.64073-43.806 39.6425-58.829 52.1994-41.35 125.4821-54.514 188.8431-33.939 63.362 20.576 126.106 43.663 143.978 107.78 6.113 21.881 8.527 44.758 16.167 66.152 20.043 56.063 78.466 96.02 138.04 94.405 76.273-2.058 128.14-59.891 191.368-93.277 45.844-24.204 114.163-1.515 139.233 56.119 22.523 51.749-14.13 106.529-25.956 156.132-12.114 50.842 7.818 101.153 49.232 133.123 41.746 32.257 105.13 48.452 156.846 35.797 50.03-12.235 84.13-49.846 122.07-81.861 49.16-41.483 127.43-43.319 187.69-35.443 28.79 3.761 58.22 9.823 86.53 3.407 49.23-11.15 57.18-52.412 57.67-95.776.68-60.09.02-120.202-1.11-180.27-2.24-118.233-6.42-236.444-6.42-354.72131h-1483.8014v281.20131z"
        fill="#ebf4ff"
        className="pulsate-2"
      />
      <path
        d="m1524.87 305.304c-22.19 75.665-103.56 116.861-179.92 136.795-49.59 12.943-101.32 20.687-152.17 14.16s-100.97-28.518-134.41-67.325c-29.47-34.204-44.4-79.028-74.01-113.122-41.481-47.811-107.788-68.674-171.105-67.435s-124.552 22.102-183.13 46.151c-33.84 13.895-68.61 29.227-105.196 28.143-43.252-1.284-82.562-25.488-115.317-53.741-32.755-28.23-61.457-61.306-96.847-86.174-96.78-68.055-240.0465-60.0459-328.6549 18.341v-169.09706h1511.8599z"
        fill="#2f80ed"
        opacity=".3"
        className="pulsate-1"
      />
      <path
        d="m623.282 521.593c2.635 9.624 7.264 18.938 13.088 27.169 23.587 33.363 62.144 64.094 105.174 47.412 32.777-12.699 57.183-48.872 70.869-79.891 10.343-23.43 11.561-49.514-2.103-72.059-23.299-38.452-72.375-43.276-109.958-24.713-25.004 12.345-50.428 31.77-68.212 53.342-11.914 14.491-13.398 32.08-8.858 48.74z"
        fill="#ebf4ff"
        className="pulsate-3"
      />
      <g fill="#2f80ed" className="bob-1">
        <path d="m1247.4 437.54c0-4.47-3.08-7.545-7.55-7.545 4.47 0 7.55-3.075 7.55-7.544 0 4.469 3.08 7.544 7.55 7.544-4.47 0-7.55 3.075-7.55 7.545z" />
        <path d="m1299.3 491.41c0-4.469-3.07-7.545-7.55-7.545 4.48 0 7.55-3.075 7.55-7.544 0 4.469 3.08 7.544 7.56 7.544-4.45 0-7.56 3.076-7.56 7.545z" />
        <path d="m1326.8 356.03c0-4.469-3.08-7.544-7.55-7.544 4.47 0 7.55-3.075 7.55-7.545 0 4.47 3.08 7.545 7.55 7.545-4.47 0-7.55 3.075-7.55 7.544z" />
        <path d="m1114.7 405.83c0-4.469-3.08-7.544-7.56-7.544 4.48 0 7.56-3.075 7.56-7.544 0 4.469 3.07 7.544 7.55 7.544-4.48 0-7.55 3.097-7.55 7.544z" />
        <path d="m1193.8 321.49c0-4.469-3.08-7.544-7.55-7.544 4.47 0 7.55-3.076 7.55-7.545 0 4.469 3.08 7.545 7.55 7.545-4.47 0-7.55 3.097-7.55 7.544z" />
        <path d="m1148.7 369.75c0-7.987-5.52-13.496-13.51-13.496 7.99 0 13.51-5.509 13.51-13.496 0 7.987 5.51 13.496 13.51 13.496-7.98 0-13.51 5.509-13.51 13.496z" />
        <path d="m1263.8 348.48c0-7.987-5.51-13.496-13.51-13.496 8 0 13.51-5.509 13.51-13.496 0 7.987 5.52 13.496 13.51 13.496-7.97 0-13.51 5.509-13.51 13.496z" />
        <path d="m1306.8 416.21c0-6.128-4.26-10.376-10.39-10.376 6.16 0 10.39-4.248 10.39-10.377 0 6.151 4.25 10.377 10.38 10.377-6.13 0-10.38 4.248-10.38 10.376z" />
        <path d="m1214 375.94c0-5.31-3.67-8.96-8.97-8.96 5.32 0 8.97-3.651 8.97-8.96 0 5.309 3.66 8.96 8.97 8.96-5.29-0.022-8.97 3.65-8.97 8.96z" />
        <path d="m186.7 244.2c0-4.004 2.769-6.77 6.777-6.77-4.008 0-6.777-2.765-6.777-6.77 0 4.005-2.768 6.77-6.777 6.77 4.009 0 6.777 2.766 6.777 6.77z" />
        <path d="m140.1 292.58c0-4.004 2.768-6.77 6.777-6.77-4.009 0-6.777-2.765-6.777-6.77 0 4.005-2.768 6.77-6.777 6.77 4.009 0 6.777 2.766 6.777 6.77z" />
        <path d="m115.39 170.99c0-4.005 2.769-6.77 6.777-6.77-4.008 0-6.777-2.766-6.777-6.77 0 4.004-2.768 6.77-6.776 6.77 4.008 0 6.776 2.765 6.776 6.77z" />
        <path d="m305.92 215.72c0-4.005 2.769-6.771 6.777-6.771-4.008 0-6.777-2.765-6.777-6.77 0 4.005-2.768 6.77-6.776 6.77 4.008 0 6.776 2.766 6.776 6.771z" />
        <path d="m234.89 139.97c0-4.004 2.769-6.77 6.777-6.77-4.008 0-6.777-2.765-6.777-6.77 0 4.005-2.768 6.77-6.777 6.77 4.009 0 6.777 2.766 6.777 6.77z" />
        <path d="m275.4 183.31c0-7.168 4.96-12.124 12.136-12.124-7.176 0-12.136-4.956-12.136-12.124 0 7.168-4.961 12.124-12.137 12.124 7.176 0 12.137 4.956 12.137 12.124z" />
        <path d="m172 164.22c0-7.169 4.961-12.124 12.137-12.124-7.176 0-12.137-4.956-12.137-12.125 0 7.169-4.961 12.125-12.136 12.125 7.175 0 12.136 4.955 12.136 12.124z" />
        <path d="m133.33 225.06c0-5.509 3.809-9.315 9.324-9.315-5.515 0-9.324-3.805-9.324-9.314 0 5.509-3.809 9.314-9.346 9.314 5.537-0.022 9.346 3.806 9.346 9.315z" />
        <path d="m216.75 188.86c0-4.757 3.3-8.054 8.062-8.054-4.762 0-8.062-3.296-8.062-8.053 0 4.757-3.299 8.053-8.061 8.053 4.784 0 8.061 3.297 8.061 8.054z" />
      </g>
    </svg>
  );
};

const RSSPContent = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-wrap flex-col md:flex-row justify-around">
      <div className="md:w-1/2 basis-auto shrink-0 grow-0 ">
        <div className="px-3 pt-6 pb-24 md:py-24">
          <h2 className="text-5xl xl:text-6xl mb-8">
            {t("HomePage.Hero.rssp.title")}
          </h2>
          <p className="whitespace-pre-wrap pb-7">
            {t("HomePage.Hero.rssp.subtitle")}
          </p>
          <NavLink
            end
            to="/RSSP"
            className="inline-flex justify-center items-center h-16 px-6 text-lg font-medium text-white rounded-full bg-blue-500 duration-300 hover:bg-blue-600"
          >
            {t("HomePage.Hero.rssp.CTA")}
            <LineIcon
              name="chevron-right"
              style={{ paddingLeft: "20px", fontWeight: "600" }}
            />
          </NavLink>
        </div>
      </div>
      <div className="md:w-1/2 self-end basis-auto shrink-0 grow-0 px-3">
        <img className="max-w-[90%]" src={heroImage} alt="" />
      </div>
    </div>
  );
};

const AutoTitle = () => {
  const { t, i18n } = useTranslation();
  const [key, setKey] = useState(0);

  useEffect(() => {
    const handleLanguageChange = () => {
      setKey((prevKey) => prevKey + 1);
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);

  const accentWords = t("HomePage.Hero.revelat.accentWord", {
    returnObjects: true,
  });

  return (
    <Typewriter
      key={key}
      onInit={(typewriter) => {
        typewriter
          .typeString(t("HomePage.Hero.revelat.title"))
          .typeString(` <span class='color-change'>${accentWords[0]}</span>`)
          .pauseFor(2500)
          .deleteChars(accentWords[0].length)
          .typeString(` <span class='color-change'>${accentWords[1]}</span>`)
          .pauseFor(2500)
          .deleteChars(accentWords[1].length)
          .typeString(` <span class='color-change'>${accentWords[2]}</span>`)
          .pauseFor(2500)
          .start();
      }}
      options={{
        autoStart: true,
        loop: true,
        pauseFor: 4000,
      }}
    />
  );
};

const RevelatContent = () => {
  const { t } = useTranslation();
  return (
    <div className="mt-36 mb-16 mx-auto w-full md:w-2/3 text-center">
      <div className="text-5xl md:text-5xl lg:text-7xl font-bold drop-shadow-md">
        <AutoTitle />
      </div>
    </div>
  );
};

const Hero = () => {
  return (
    <section id="home">
      <div className="relative">
        <HeroBg />
        <div className="absolute flex inset-0 items-center justify-center p-4">
          <RevelatContent />
          {/* <Carousel>
            <RevelatContent />
            <RSSPContent />
          </Carousel> */}
        </div>
      </div>
    </section>
  );
};

export default Hero;

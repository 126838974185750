import EventBus from "utils/EventBus";

const ContactUs = ({ t }) => {
  return (
    <section id="contact-us" className="relative w-full">
      <svg
        className="w-full h-auto fill-gray-200 -mb-[1px]"
        viewBox="0 0 1440 295"
        preserveAspectRatio="none"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="m1000.6 0.023184c-68.379-0.53806-137.4 8.3694-192.49 18.417-114.57 20.896-306.52 55.657-395.46 22.26-108.28-40.661-225.44-58.608-333.66 0-43.827 23.735-67.042 61.546-78.995 100.97v153.33h1440v-135.96c-22.279-3.5695-134.13-24.051-209.79-92.447-54.835-49.571-141.69-65.878-229.61-66.57z" />
      </svg>
      <div className="w-full h-16 md:h-10 lg:h-0 bg-gray-200"></div>

      <div className="absolute inset-0 flex flex-wrap items-center justify-center">
        <div className="md:w-1/2 lg:w-2/5 text-center mt-4">
          <h3 className="text-4xl mb-4 tracking-wide drop-shadow-md">
            {t("AboutPage.contact.title")}
          </h3>
          <p className="whitespace-pre-wrap">
            {t("AboutPage.contact.subtitle")}
          </p>
          <button
            className="primary-button mt-2 md:mt-3 lg:mt-6"
            onClick={() => EventBus.dispatch("ShowModal")}
          >
            {t("AboutPage.contact.button")}
          </button>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;

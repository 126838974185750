import ContentContainer from "components/ContentContainer";

const Statement = ({ icon, title, subtitle }) => {
  return (
    <div className="border border-gray-200 rounded-lg shadow text-center w-full md:w1/2 xl:w-1/4 p-5 my-4 md:mx-4 xl:mx-12">
      <div className="mx-auto flex h-16 w-16 -translate-y-12 transform items-center justify-center rounded-full bg-blue-400 shadow-lg shadow-blue-500/40">
        <div className="text-white text-4xl flex items-center justify-center">
          <i className={`lni ${icon}`} style={{ fontWeight: 800 }}></i>
        </div>
      </div>
      <h1 className="text-2xl font-bold -mt-5 mb-4 lg:px-14">{title}</h1>
      <p className="px-4">{subtitle}</p>
    </div>
  );
};

const MissionVision = ({ t }) => {
  return (
    <section id="statements">
      <ContentContainer>
        <div className="flex flex-col md:flex-row justify-center">
          <Statement
            title={t("AboutPage.mission.title")}
            subtitle={t("AboutPage.mission.subtitle")}
            icon="lni-target"
          />
          <Statement
            title={t("AboutPage.vision.title")}
            subtitle={t("AboutPage.vision.subtitle")}
            icon="lni-eye"
          />
        </div>
      </ContentContainer>
    </section>
  );
};

export default MissionVision;

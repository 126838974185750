import React from "react";
import { useTranslation } from "react-i18next";
import SlidingSection from "components/SlidingSection";
import WordCarousel from "components/WordCarousel";
import InfiniteVerticalCarousel from "components/InfiniteVerticalCarousel";
import techStack from "assets/data/techStack";

const Talent = () => {
  const { t } = useTranslation();
  return (
    <section id="talent" className="mb-4">
      <div className="relative w-full z-[1]">
        <div className="w-full h-56 md:h-16 lg:hidden -mb-[1px] bg-primary"></div>
        <svg
          className="w-full h-auto"
          viewBox="0 0 1 0.441"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m0 0v0.16602s0.024084 0.10292 0.083984 0.13672c0.0599 0.0338 0.11074 0.031209 0.16992-0.025391 0.0437-0.04183 0.10685-0.012549 0.15234 0.025391 0.0455 0.03803 0.21373 0.13235 0.30273 0.13672 0.0521 0.0025 0.16364 0.009976 0.24023-0.058594 0.0376-0.03373 0.050781-0.074075 0.050781-0.16016v-0.2207z"
            fill="#2f80ed"
          />
        </svg>

        <div className="absolute flex flex-col-reverse md:flex-row inset-0 py-5 p-6 md:px-10 lg:px-16 xl:px-20 text-white">
          <div className="flex-1 md:pr-4">
            <h1 className="text-2xl md:text-4xl xl:text-6xl text-white drop-shadow-md mb-2 md:my-5 xl:my-10 2xl:my-14">
              {t("HomePage.Talent.title")}
            </h1>
            <p className="whitespace-pre-wrap lg:text-lg">
              {t("HomePage.Talent.section1")}
            </p>
          </div>
          <div className="md:flex-1 content-center ">
            <div className="flex items-center md:justify-end text-2xl lg:text-4xl xl:text-5xl 2xl:text-6xl">
              <div className="flex items-center mr-2 xl:h-32 ">
                <span>{t("HomePage.Talent.experts")}</span>
              </div>
              <WordCarousel
                words={[
                  "XFS",
                  "POS",
                  "Embedded",
                  "Telemetry",
                  "Device integration",
                ]}
                elementClass="font-bold drop-shadow-lg md:text-left"
                width="w-1/2"
                height="h-20 lg:h-20 xl:h-32"
              />
            </div>
          </div>
        </div>
      </div>

      <SlidingSection
        zIndex={0}
        color="#8cb4eb"
        delay={-0.3}
        totalTravel={0.65}
        minBound={0.35}
      >
        <div
          className="w-full h-48 md:h-16 lg:hidden -mb-[1px]"
          style={{
            backgroundColor: "#8cb4eb",
          }}
        ></div>
        <svg
          className="w-full h-auto"
          viewBox="0 0 1 0.441"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m0 0v0.40625s0.065721-0.048401 0.11914-0.019531c0.06945 0.03753 0.17023 0.054688 0.25781 0.054688 0.06944 0 0.21378-0.030354 0.23633-0.12109 0.01875-0.07543 0.17675-0.021484 0.23047-0.021484 0.07222 0 0.13195-0.006889 0.15625-0.074219v-0.22461z"
            fill="#8cb4eb"
          />
        </svg>

        <div className="absolute flex inset-0 items-center py-5 md:px-10 text-white">
          <div className="flex-1">
            <div className="flex flex-row justify-around mt-24 ml-2 md:mx-4">
              <InfiniteVerticalCarousel
                speed={20}
                list={techStack.slice(0, 5)}
              />
              <InfiniteVerticalCarousel
                direction="up"
                speed={40}
                list={techStack.slice(5, 10)}
              />
              <InfiniteVerticalCarousel speed={30} list={techStack.slice(10)} />
            </div>
          </div>
          <div className="flex-1 text-center text-xl lg:text-3xl font-bold mt-32 md:mt-24 lg:mt-32">
            {t("HomePage.Talent.section2")}
          </div>
        </div>
      </SlidingSection>
    </section>
  );
};

export default Talent;

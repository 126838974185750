import { useTranslation } from "react-i18next";
import MissionVision from "./sections/MissionVision";
import GlobalPartner from "./sections/GlobalPartner";
import Specialists from "./sections/Specialists";
import aboutImage from "assets/img/about/about-img.svg";
import RevealOnScroll from "utils/RevealOnScroll";
import ContactUs from "./sections/ContactUs";

const About = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="mt-12 md:mt-24 bg-primary">
        <div className="flex flex-wrap flex-col md:flex-row justify-around pt-4 mx-auto max-w-xl md:max-w-4xl lg:max-w-screen-lg xl:max-w-screen-xl">
          <div className="m-auto md:w-1/2 self-end basis-auto shrink-0 grow-0 px-3">
            <img className="max-w-[90%]" src={aboutImage} alt="" />
          </div>
          <div className="md:w-1/2 basis-auto shrink-0 grow-0 ">
            <div className="px-8 md:px-3 py-8 md:py-24 text-start">
              <h1 className="text-4xl md:text-5xl xl:text-6xl mb-6 text-white text-center md:text-left drop-shadow-md">
                {t("AboutPage.Hero.title")}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <Specialists t={t} />
      <RevealOnScroll>
        <GlobalPartner t={t} />
      </RevealOnScroll>
      <RevealOnScroll>
        <MissionVision t={t} />
      </RevealOnScroll>
      <ContactUs t={t} />
    </>
  );
};

export default About;

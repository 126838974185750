import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import partners from "assets/data/partners";
import Section from "components/Section";

const PartnerItem = ({ title, description, image, url, t }) => {
  return (
    <div className="flex-none w-full lg:w-[450px] xl:w-[600px] snap-always snap-center bg-white border hover:bg-gray-100 border-gray-200 rounded-lg shadow">
      <div className="flex flex-col items-center lg:flex-row lg:max-w-xl p-4">
        <img
          className="object-contain lg:object-cover w-full rounded-t-lg h-96 lg:h-auto lg:w-48 lg:rounded-none lg:rounded-s-lg"
          src={`/img/${image}`}
          alt=""
          loading="lazy"
        />
        <div className="w-full flex flex-col justify-between leading-normal lg:pl-4 lg:pt-4">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
            {title}
          </h5>
          <p className="mb-8 font-normal text-gray-700 whitespace-pre-wrap">
            {t(`HomePage.Partners.${description}`)}
          </p>
          <div>
            <a
              href={url}
              target="_blank"
              rel="noopener"
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-primary-500 rounded-lg hover:bg-primary-600"
            >
              {t("HomePage.Partners.learnMore")}
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const Partners = () => {
  const scrollContainer = useRef(null);
  const { t } = useTranslation();

  const scroll = (direction) => {
    const container = scrollContainer.current;
    if (container) {
      const scrollAmount = direction === "left" ? -500 : 500;
      container.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const Arrow = ({ left, className }) => (
    <button
      type="button"
      onClick={() => scroll(left ? "left" : "right")}
      className={`absolute hover:text-white text-2xl z-[1] hover:bg-black h-10 w-10 rounded-full opacity-75 flex items-center justify-center ${
        left ? "left-2" : "right-2"
      } ${className || ""} `}
    >
      <span role="img" aria-label={`Arrow ${left ? "left" : "right"}`}>
        {left ? "<" : ">"}
      </span>
    </button>
  );

  return (
    <Section name="partners" title={t("HomePage.Partners.title")}>
      <div className="relative w-full">
        {/* TODO: if a third partner or more ar added, remove the lg:justify-center */}
        <div
          className="flex flex-nowrap overflow-x-auto snap-x snap-mandatory gap-8 no-scrollbar pb-5 lg:justify-center"
          ref={scrollContainer}
        >
          {partners.map((partner, index) => (
            <PartnerItem
              key={`partner-${index}`}
              title={partner.name}
              image={partner.image}
              description={partner.abstract_key}
              url={partner.url}
              t={t}
            />
          ))}
        </div>
        <Arrow left={true} className="lg:hidden" />
        <Arrow left={false} className="lg:hidden" />
      </div>
    </Section>
  );
};

export default Partners;

import SlidingSection from "components/SlidingSection";

const SectionContainer = ({ children, right = false }) => {
  return (
    <div className="absolute flex inset-0 pb-3 md:py-5 px-10 xl:px-20 text-white">
      {right && <div className="md:flex-1"></div>}
      {children}
      {!right && <div className="md:flex-1"></div>}
    </div>
  );
};

const SectionContent = ({ text, className }) => {
  return (
    <div className={`flex-1 flex items-end md:items-center ${className ?? ""}`}>
      <h2 className="md:text-lg lg:text-2xl xl:text-3xl text-white md:mt-14 lg:mt-16 py-2 md:pb-0">
        {text}
      </h2>
    </div>
  );
};

const Specialists = ({ t }) => {
  return (
    <div id="specialists">
      <div className="relative w-full z-[2]">
        <div className="w-full h-20 md:h-5 lg:hidden bg-primary"></div>
        <svg
          className="w-full h-auto"
          viewBox="0 0 1440 400"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m0 0v246.45c5.0455 31.864 21.63 74.247 71.186 101.13 110.34 59.863 272.18 53.272 347.19 51.035 128.22-3.824 370.01-87.303 435.55-120.47 65.54-33.173 156.68-57.723 219.68-21.224 85.25 49.396 158.35 51.67 244.65 22.183 83.52-28.541 119.47-113.87 121.74-119.42v-159.68z"
            fill="#2F80ED"
          />
        </svg>

        <SectionContainer>
          <div className="flex-1">
            <h1 className="text-xl md:text-4xl xl:text-6xl text-white mb-2 md:mb-5 xl:mb-10 lg:mt-5 xl:mt-10 drop-shadow-md whiteSelection">
              {t("AboutPage.Specialists.title")}
            </h1>
            <p className="font-bold md:text-lg lg:text-xl xl:text-2xl lg:mt-10 xl:mt-15 2xl:mt-20 whiteSelection">
              {t("AboutPage.Specialists.section1")}
            </p>
          </div>
        </SectionContainer>
      </div>

      <SlidingSection
        zIndex={1}
        color="#60A5FA"
        totalTravel={1.16}
        minBound={0.55}
        delay={-0.8}
      >
        <div
          className="w-full h-14 md:hidden"
          style={{
            backgroundColor: "#60A5FA",
          }}
        ></div>
        <svg
          className="w-full h-auto"
          viewBox="0 0 1440 224"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m0 0v47.091l0.75781 2.6733s34.687 83.322 120.98 110.61c86.292 27.289 133.26 13.359 195.76 0 91.506-19.56 147.55-5.6015 239.18 13.359 155.32 32.139 336.72 46.478 464.94 50.017 75.01 2.0703 240.54-7.9757 350.88-63.376 45.315-22.753 61.888-40.543 67.5-52.551v-107.83z"
            fill="#60A5FA"
          />
        </svg>

        <SectionContainer right={true}>
          <SectionContent text={t("AboutPage.Specialists.section2")} />
        </SectionContainer>
      </SlidingSection>

      <SlidingSection
        zIndex={0}
        color="#C1D9FA"
        totalTravel={0.6}
        minBound={0.35}
        delay={-0.3}
      >
        <div
          className="w-full h-4 md:hidden"
          style={{
            backgroundColor: "#C1D9FA",
          }}
        ></div>
        <svg
          className="w-full h-auto"
          viewBox="0 0 1440 406"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m-0.86221-31.38v290.82c6.5032 16.634 19.796 35.55 47.974 58.334 57.499 46.492 153.88 59.233 253.88 56.49 128.24-3.5174 200.15-32.882 355.49-64.825 91.645-18.844 237.5-39.833 330.49-26.556 63.259 9.0324 245.92 27.121 332.23 0 82.872-26.041 117.25-101.68 119.93-107.8v-206.47z"
            fill="#C1D9FA"
          />
        </svg>
        <SectionContainer>
          <SectionContent
            text={t("AboutPage.Specialists.section3")}
            className="pb-4 md:pb-0"
          />
        </SectionContainer>
      </SlidingSection>
    </div>
  );
};

export default Specialists;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "components/Input";
import InputContainer from "components/InputContainer";

const ContactForm = ({ className }) => {
  const [sendingMail, setsendingMail] = useState(false);
  const [mailSent, setmailSent] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormsErrors] = useState({});
  const { t } = useTranslation();

  const handleChange = (e, field) => {
    if (mailSent) {
      setmailSent(false);
    }
    let value = e.target.value;
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleValidation = () => {
    let fields = formData;
    let errors = {};
    let formIsValid = true;

    //Name
    if (!fields["firstname"]) {
      formIsValid = false;
      errors["firstname"] = t("Contact.required", { field: t("Contact.name") });
    }

    if (typeof fields["firstname"] !== "undefined") {
      if (!fields["firstname"].match(/^[a-z A-Z]+$/)) {
        formIsValid = false;
        errors["firstname"] = t("Contact.lettersOnly");
      }
    }

    //Email
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = t("Contact.required", { field: t("Contact.email") });
    }

    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = t("Contact.invalid", { field: t("Contact.email") });
      }
    }

    setFormsErrors(errors);
    return formIsValid;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (sendingMail) {
      return;
    }
    if (mailSent) {
      setmailSent(false);
    }
    if (handleValidation()) {
      setsendingMail(true);
      fetch("contact/index.php", {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          return response.text();
        })
        .then((data) => {
          try {
            let d = JSON.parse(data);
            if (d.sent) {
              setmailSent(true);
            } else {
              setmailSent(false);
              console.warn("email not sent", d);
              setError(t("Contact.error"));
              //Handle Error
            }
          } catch (e) {
            console.error("email not sent", e);
            setError(t("Contact.error"));
          }
          setsendingMail(false);
        })
        .catch((error) => {
          console.error("Unable to send:", error);
          setError(t("Contact.error"));
        });
    }
  };

  return (
    <div className={className}>
      <div className="text-center">
        <h3 className="text-3xl mb-5 tracking-wide">{t("Contact.title")}</h3>
      </div>
      <form action="#">
        <div className="flex flex-wrap shrink-0">
          <InputContainer small={true}>
            <Input
              type="text"
              name="firstname"
              placeholder={t("Contact.name")}
              icon="lni-user"
              onChange={(e) => handleChange(e, "firstname")}
              error={formErrors["firstname"]}
            />
          </InputContainer>
          <InputContainer small={true}>
            <Input
              type="email"
              name="email"
              placeholder={t("Contact.email")}
              icon="lni-envelope"
              onChange={(e) => handleChange(e, "email")}
              error={formErrors["email"]}
            />
          </InputContainer>
          <InputContainer>
            <div className="relative mb-5">
              <textarea
                name="message"
                id="message"
                className="relative py-4 pl-12 pr-5 w-full shadow rounded resize-none"
                placeholder={t("Contact.message")}
                rows="6"
                onChange={(e) => handleChange(e, "msg")}
              ></textarea>
              <i className="lni lni-comments-alt absolute left-5 top-5"></i>
            </div>
          </InputContainer>
          <InputContainer>
            <div className="pt-2.5 pb-0.5">
              <button
                disabled={mailSent}
                type="submit"
                className={`h-14 px-6 rounded transition duration-300 text-white text-lg ${
                  mailSent ? "bg-green-500" : "bg-blue-500 hover:bg-blue-600"
                }`}
                onClick={(e) => handleFormSubmit(e)}
              >
                <i className="lni lni-telegram-original pr-4"></i>
                {mailSent ? t("Contact.sent") : t("Contact.send")}
              </button>
            </div>
          </InputContainer>
        </div>
        <div>
          {error && (
            <div className="m-3 text-red-500 font-semibold">
              {t("Error")}: {error}
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default ContactForm;

import React from "react";
import EventBus from "utils/EventBus";
import { useTranslation } from "react-i18next";

const ServiceItem = ({ title, description, className }) => {
  return (
    <div className={`flex flex-col p-4 ${className ?? ""}`}>
      <h3 className="text-lg lg:text-2xl font-semibold mb-2 text-inherit">
        {title}
      </h3>
      <p className="text-sm lg:text-base whitespace-pre-wrap">{description}</p>
    </div>
  );
};

const Services = () => {
  const { t } = useTranslation();
  const items = t("HomePage.Services.items", { returnObjects: true });
  return (
    <div className="relative mt-5 md:mb-16">
      <div className="absolute left-0 right-0 h-1/2 bg-gray-200 top-1/4 -z-10"></div>

      <div className="bg-primary text-white p-8 md:rounded-3xl max-w-full md:max-w-3xl mx-auto relative">
        <h2 className="text-4xl font-bold mb-6 text-center text-inherit">
          {t("HomePage.Services.title")}
        </h2>

        <div className="grid md:grid-cols-2 gap-4 text-center">
          {items.map((item, index) => (
            <ServiceItem
              key={`service-${index}`}
              title={item.title}
              description={item.description}
              className={`border-b md:border-b-0 ${
                index % 2 === 0 ? "md:border-r" : ""
              }  border-white`}
            />
          ))}
        </div>

        <div className="text-center mt-6">
          <button
            className="primary-button-inverse"
            onClick={() => EventBus.dispatch("ShowModal")}
          >
            {t("HomePage.Services.button")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Services;

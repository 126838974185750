import React from 'react';
import ECharts from "components/ECharts";
import { outbound } from "assets/data/mapData";

function invertAndAdd(arr) {
  const inverted = arr.map((subarray) => [...subarray].reverse());
  return [...arr, ...inverted];
}
//Add inbound routes
const routes = invertAndAdd(outbound); 

let mapData = {
  geo3D: {
    map: "world",
    silent: true,
    viewControl: {
      alpha: 90,
      beta: 0,
      projection: "orthographic",
      autoRotate: false,
      rotateMouseButton: "none",
      zoomSensitivity: 0,
      distance: 100,
      minDistance: 100,
      maxDistance: 100,
    },
    postEffect: {
      enable: false,
    },
    light: {
      main: {
        intensity: 1,
        shadow: false,
      },
      ambient: {
        intensity: 0.5,
      },
    },
    environment: "#fff",
    itemStyle: {
      color: "#60A5FA",
    },
  },
  series: [
    {
      type: "lines3D",
      coordinateSystem: "geo3D",
      effect: {
        show: true,
        trailWidth: 2,
        trailOpacity: 0.5,
        trailLength: 0.2,
        constantSpeed: 10,
      },
      blendMode: "darken",
      color: "f00",
      lineStyle: {
        color: "#FFBD54",
        width: 0.5,
        opacity: 0.05,
      },
      data: routes,
    },
  ],
};

const GlobalPartner = ({ t }) => {
  return (
    <section id="global-partner">
      <div className="sticky top-[10vh] md:top-[15vh] h-96 lg:h-[600px] flex items-center justify-center">
        <div className="absolute h-full inset-x-0 top-0 select-none pointer-events-none">
          <ECharts options={mapData} className="h-full" />
        </div>
      </div>
      <div className="relative h-96 lg:h-[600px] container mx-auto px-4 py-8 flex flex-col justify-center items-center">
        <h3 className="text-4xl mb-6 tracking-wide drop-shadow-md">
          {t("AboutPage.global.title")}
        </h3>
        <p className="relative whitespace-pre-wrap text-xl font-medium">
          {t("AboutPage.global.subtitle")}
        </p>
      </div>
    </section>
  );
};

export default GlobalPartner;
